<template>
  <v-app>
    <login-header></login-header>
    <v-content>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="5">
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Login</v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    v-model="user.uid"
                    :rules="[rules.required, rules.uid]"
                    prepend-icon="email"
                    placeholder="Enter your email id"
                    clearable
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="user.pass"
                    :rules="[v => !!v || 'password required']"
                    prepend-icon="lock"
                    placeholder="Enter your password"
                    clearable
                    :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="() => (value = !value)"
                    :type="value ? 'password' : 'text'"
                    required
                  ></v-text-field>
                  <!-- <v-btn class="mr-4" @click="clear">Reset</v-btn> -->
                  <v-btn class="mr-4" color="primary" @click="signIn">Login</v-btn>
                </v-form>
                <br />

                <v-col cols="12">
                  <div v-show="message!=null">
                    <v-alert outlined type="error" prominent border="left" dismissible>{{message}}</v-alert>
                  </div>
                </v-col>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
    <login-footer></login-footer>
  </v-app>
</template>

<script>
import axios from "axios";
import LoginHeader from "../LoginHeader.vue";
import LoginFooter from "../LoginFooter.vue";
export default {
  components: {
    LoginHeader: LoginHeader,
    LoginFooter: LoginFooter
  },
  data() {
    return {
      user: {
        uid: "",
        pass: ""
      },
      value: String,
      message: "",
      dialog: false,
      read_policy_load: false,
      rules: {
        required: value => !!value || "Required.",
        uid: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        }
      }
    };
  },
  methods: {
    readPolicy() {
      this.read_policy_load = true;
      axios
        .post("/login/readPolicy", this.user)
        .then(res => {
        //   window.console.log("res" + res.data.msg);
          if (res.data.msg == "200") {
            this.$router.push("/home");
          }
        })
        .catch(error => {
          // window.console.log(error);
          this.message = "Something went wrong";
        })
        .finally(() => {
          this.dialog = false;
          this.read_policy_load = false;
        });
    },
    signIn() {
      if (this.perormLoginFormValidation()) {
        this.user.dns = window.location.hostname;
        axios
          .post("/SuperAdminHome/process", this.user)
          .then(res => {
            if (res.data.msg == "200") {
              localStorage.setItem("awspath", res.data.awspath);
              localStorage.setItem("EPS-token", res.data.token);
              localStorage.setItem("EPS-uid", res.data.uid);
               this.$router.push({ name: 'eps-admin-home'})  
              //this.$router.push("/home");
            } else {
              this.message = "Please check ur credentials!!!";
              localStorage.setItem("EPS-token", "");
              localStorage.setItem("EPS-uid", "");
            }
          })
          .catch(error => {
            // window.console.log(error);
            this.message = "Something went wrong";
          });
      } else {
        // console.log("I am in else block");
      }
    },
    clear() {
      this.user.uid = "";
      this.user.pass = "";
    },
    perormLoginFormValidation() {
      if (this.user.uid == "" && this.user.pass == "") {
        return false;
      } else {
        return true;
      }
    }
  },
  mounted() {
    this.message = localStorage.getItem("login-page-msg");
    // console.log("login-page-msg>>" + localStorage.getItem("login-page-msg"));

    //this.$store.commit('setquick_links', false);
    localStorage.clear();
  }
};
</script>
<style scoped>
.pull-right {
  position: relative;
  text-align: center;
}
</style>